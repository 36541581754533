import React, { useEffect, useRef } from 'react';
import gsap from "gsap";
import { useGSAP } from '@gsap/react';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Animation from './Animation';
import Video from './Video';
import About from './About';
import Map from './Map';
import Form from './Form';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Home = () => {

  useEffect(() => {
    AOS.init();
    gsap.registerPlugin(ScrollTrigger);

    let panels = gsap.utils.toArray(".parallaxSection");
    // we'll create a ScrollTrigger for each panel just to track when each panel's top hits the top of the viewport (we only need this for snapping)
    let tops = panels.map(panel => ScrollTrigger.create({trigger: panel, start: "top top"}));

    panels.forEach((panel, i) => {
      ScrollTrigger.create({
        trigger: panel,
        start: () => panel.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
        pin: true, 
        pinSpacing: false 
      });
    });

    setTimeout(() => {
      const section = document.querySelector(`#video-section`)

      section.scrollIntoView({
        behavior: "smooth",
        block: 'start'
      });

      // window.scrollTo({
      //   top: window.innerHeight + 100,
      //   behavior: "smooth",
      // });

    }, 2500);

  }, []);

  return (
    <>
      <Animation />
      <Video />
      <About />
      <Map />
      <Form />
  </>
  );
};

export default Home;
