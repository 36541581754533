import { makeStyles } from "@mui/styles";

const VideoStyles = makeStyles((theme) => ({
    videoSection: {
        width: `100%`,
        backgroundColor: `#C7613C`,
        padding: 80,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingBottom: 100,
        },
    },
    videoWrapper: {
        // backgroundColor: `blue`,
        position: `relative`,
        width: `120%`,
        marginLeft: `-10%`,
        height: `70vh`,
        [theme.breakpoints.down('md')]: {
            height: `50vh`,
        },
    },
    videoContent: {
        zIndex: 0,
        // backgroundColor: `red`,
        position: `absolute`,
        marginLeft: `0`,
        width: `100%`,
        height: `84vh`,
        textAlign: `center`,
        [theme.breakpoints.down('md')]: {
            height: `53vh`,
        },
        [theme.breakpoints.down('sm')]: {
            height: `45vh`,
            width: `139%`,
            marginLeft: `-20%`,
            maxHeight: 480,
        },
        '& video': {
            height: `99%`,
            // [theme.breakpoints.down('sm')]: {
            //     height: `auto`,
            //     width: `100%`,
            // },
        }
    },
    videoMask: {
        zIndex: 10,
        // opacity: 0.5,
        top: -2,
        position: `absolute`,
        width: `140%`,
        marginLeft: `-20%`,
        height: `85vh`,
        backgroundSize: `contain`,
        backgroundRepeat: `no-repeat`,
        backgroundPosition: `top center`,
        [theme.breakpoints.down('md')]: {
            // backgroundColor: `green`,
            height: `55vh`,
            width: `140%`,
            marginLeft: `-20%`,
        },
        [theme.breakpoints.down('sm')]: {
            height: `50vh`,
            // minHeight: 500,
        },
    },
    logoMobile: {
        marginBottom: 0,
        justifyContent: 'center',
        display: 'flex',
        width: `50%`,
        margin: `0 auto`,
        fontFamily: `Montserrat-Light`,
        paddingTop: 80,
        paddingBottom: 50,
        [theme.breakpoints.up('sm')]: {
            display: 'none'
        },  
        '& img': {
            width: `100%`,
            margin: `0 auto`
        },
    },
}));

export default VideoStyles;
