import React from 'react';
import AppProvider from 'core/store/providers/App';
import 'mapbox-gl/dist/mapbox-gl.css';
import Main from './Main';
import 'styles/App.scss';

// fonts
import 'assets/fonts/Montserrat-Black.otf';
import 'assets/fonts/Montserrat-Bold.otf';
import 'assets/fonts/Montserrat-ExtraBold.otf';
import 'assets/fonts/Montserrat-Light.otf';
import 'assets/fonts/Montserrat-Regular.otf';
import 'assets/fonts/Montserrat-UltraLight.otf';

export const defaultSessionTimeout = 3600000;

const App = () => {
  const initialAppState = {
    fullWidth: true,
    isReadWrite: true,
    toggleDrawer: window.innerWidth > 1024,
    error: [],
    search: {
      totalCount: 0,
      tmpKeyword: '',
      hashtag: '*',
      errors: {
        hashtag: {
          isTrue: false,
          value: '',
        },
      },
    },
  };

  return (
    <AppProvider initState={initialAppState}>
      <Main />
    </AppProvider>
  );
};

export default App;
