import React from 'react';
import { makeStyles } from "@mui/styles";
import HomeStyles from "./styles";

const useStyles = makeStyles((theme) => ({
  mapSection: {
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    backgroundAttachment: `fixed`,
    backgroundPosition: `top center`,
    backgroundSize: `cover`,
    backgroundRepeat: `no-repeat`,
    height: `100vh`,
    backgroundImage: `url('/img/about/map.png')`,
    [theme.breakpoints.down('sm')]: {
      // backgroundImage: `url('/img/about/map_mobile2.jpg')`,
      backgroundImage: `none`,
      backgroundPosition: `center`,
      minHeight: `50vh`,
    },
    '& img': {
      display: `none`,
      width: `auto`,
      maxWidth: `200%`,
      minWidth: `100%`,
      height: `100%`,
      [theme.breakpoints.down('sm')]: {
        display: `flex`
      }
    }
  }
  
}));


const Map = () => {
  const classes = useStyles();
  const homeClass = HomeStyles();

  return (
    <section className={`parallaxSection ${homeClass.parallaxBg} ${classes.mapSection}`} id="map-section">
      <img src={`/img/about/map_mobile2.jpg`} />
    </section>
  );
};

export default Map;
