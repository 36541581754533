import React from "react";
import { makeStyles } from "@mui/styles";
import Lottie from 'react-lottie';
import animationData from 'assets/animation/logo';
import HomeStyles from "./styles";

const useStyles = makeStyles((theme) => ({
  animationSection: {
    maxWidth: `100%`,
    width: `100%`,
    height: `100vh`,
    position: `fixed`,
    display: `flex`,
    justifyContent: `center`,
    alignItems: `center`,
    margin: 0,
    padding: 0,
    position: `relative`,
    top: 0,
    '& svg': {
      position: `absolute`,
      top: 0,
      left: 0
    }
  }
}));

const Animation = () =>{
  const classes = useStyles();
  const homeClass = HomeStyles();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    height: `50%`,
    width: `50%`,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <section className={`parallaxSection ${homeClass.parallaxBg} ${classes.animationSection}`}>
      <Lottie options={defaultOptions} />
    </section>
  );
}

export default Animation
