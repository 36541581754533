import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from "@mui/styles";
import { Grid } from '@mui/material';
import MobileHeader from '../MobileHeader';
import HeaderStyles from './_styles';

const Header = () => {
  const classes = HeaderStyles()
  const { t } = useTranslation();
  const theme = useTheme();

  const scrollToForm = () => {
    const section = document.querySelector(`#form-section`)

    section.scrollIntoView({
      behavior: "smooth",
      block: 'start'
    });
  };

  return (
      <header className={classes.topBar}>
         <div className={classes.logoContainer}>
              <img
                src={`/img/logo/logo.svg`}
                alt='Heather Teaser'
              />
          </div>
          <div className={`${classes.btnContainer} mobile`} data-aos='fade-up' data-aos-duration='1000'>
              <div className={`${classes.topBarButton} desktop`} onClick={scrollToForm}>
                {t('commons.register')}
              </div>
          </div>
      </header>
  );
};

export default Header;
