//  General JS Library importation
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import { makeStyles } from "@mui/styles"; 

const useStyles = makeStyles((theme) => ({
  body: {
    // flex: '0 0 auto',
    // width: `100%`,
    backgroundColor: `#fff`
  },
  flex1: {
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  wrapper: {
    // alignItems: 'flex-start',
    // display: 'flex',
    // border: '10px solid red',
    // backgroundColor: 'blue',
    // margin: 0,
    // padding: 0,
    // minWidth:`100%`,
    // width: `100%`,
    // height: `auto`,
    // flexDirection: 'column',
    // justifyContent: 'center',
  },
}));

const ContentMain = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapper} item xs={12} md={12} lg={12} xl={12}>
      <Grid className={clsx(classes.body)} {...props}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ContentMain;

ContentMain.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
};
