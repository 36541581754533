//  General JS Library importation
import React, { useEffect } from 'react';
import ContentMain from 'components/ContentMain';
import { Grid } from '@mui/material';
import Footer from '../_layout/Footer';
import { makeStyles } from "@mui/styles";
import AOS from 'aos';
import 'aos/dist/aos.css';

const useStyles = makeStyles((theme) => ({
  thankYouSection: {
    width: `100%`,
    height: `100vh`,
    backgroundColor: `#C7613C`,
    color: `#fff`,
    display: `flex`,
    flexDirection: `column`,
    alignContent: `center`,
    justifyContent: `center`,
    flexWrap: `wrap`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  logo: {
    width: `100%`,
    textAlign: `center`,
    paddingTop: 62,
    [theme.breakpoints.down('md')]: {
      width: `70%`,

    },
  },
  content: {
    padding: `2% 10%`,
    color: `#fff`,
    textAlign: `center`,
    '& p, & a': {
      fontSize: 38,
      lineHeight: 1.33,
      color: `#fff`,
      marginBottom: 18,
      fontFamily: 'LibreCaslonText-Regular',
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        fontSize: 32,
      },
    }
  }
}));

const ThankYou = () => {
  const classes = useStyles();

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <ContentMain>
      <Grid container justifyContent="center" className={classes.thankYouSection}>
        <div className={classes.logo}  data-aos='fade-up' data-aos-duration='1000'>
          <img
            src={`/img/logo/logo_white.svg`}
            alt='Heather Teaser'
          />
        </div>
        <div className={classes.content}  data-aos='fade-up' data-aos-delay='100' data-aos-duration='1000'>
          <p>Thank you for registering</p>
        </div>
      </Grid>
      <Footer/>
    </ContentMain>
  );
};

export default ThankYou;