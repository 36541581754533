import React from 'react';
import { useTranslation } from 'react-i18next';
import MetaTitle from 'components/MetaTitle';
import PrivacyPolicy from './PrivacyPolicy';

const PrivacyPolicyPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTitle title={`${t('Portfolio.title')} : ${t('siteNameFull')}`} />
      <PrivacyPolicy />
    </>
  );
};

export default PrivacyPolicyPage;
