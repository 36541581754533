import MontserratLightUrl from 'assets/fonts/Montserrat-Light.otf';
import MontserratRegularUrl from 'assets/fonts/Montserrat-Regular.otf';
import AvenirBlackUrl from 'assets/fonts/Avenir-Medium.ttf';
import LibreCaslonRegularUrl from 'assets/fonts/LibreCaslonText-Regular.ttf';
 
export const MontserratLight = {
 fontFamily: 'MontserratLight',
 fontStyle: 'light',
 fontDisplay: 'swap',
 fontWeight: '400',
 src: `
   local('MontserratLight'),
   local('Montserrat-Light'),
   url(${MontserratLightUrl}) format('opentype')
 `,
};

export const MontserratRegular = {
  fontFamily: 'MontserratRegular',
  fontStyle: 'medium',
  fontDisplay: 'swap',
  fontWeight: '500',
  src: `
    local('MontserratRegular'),
    local('Montserrat-Regular'),
    url(${MontserratRegularUrl}) format('opentype')
  `,
 };
 
export const LibreCaslonRegular = {
  fontFamily: 'LibreCaslonRegular',
  fontStyle: 'light',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `
    local('LibreCaslonRegular'),
    local('LibreCaslon-Regular'),
    url(${LibreCaslonRegularUrl}) format('ttf')
  `,
 };

export const AvenirBlack = {
  fontFamily: 'Avenir-Medium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
      local('Avenir-Medium'),
      local('Avenir-Medium'),
      url(${AvenirBlackUrl}) format('ttf')
    `,
};




// export const LibreCaslonRegular = {
//   fontFamily: 'LibreCaslon-Regular',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 700,
//   src: `
//       local('LibreCaslon-Regular'),
//       local('LibreCaslon-Regular'),
//       url(${LibreCaslonRegularUrl}) format('ttf')
//     `,
// };

// export const MontserratRegular = {
//   fontFamily: 'Montserrat-Regular',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 700,
//   src: `
//       local('Montserrat-Regular'),
//       local('Montserrat-Regular'),
//       url(${MontserratUrl}) format('otf')
//     `,
// };

// export const AvenirBook = {
//   fontFamily: 'LibreCaslonText-Bold',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 700,
//   src: `
//         local('LibreCaslonText-Bold'),
//         local('LibreCaslonText-Bold'),
//         url(${AvenirBookUrl}) format('ttf')
//       `,
// };

// export const AvenirMedium = {
//   fontFamily: 'Avenir-Medium',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 500,
//   src: `
//         local('Avenir-Medium'),
//         local('Avenir-Medium'),
//         url(${AvenirMediumUrl}) format('ttf')
//       `,
// };

// export const AvenirHeavy = {
//   fontFamily: 'Avenir-Heavy',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 500,
//   src: `
//         local('Avenir-Heavy'),
//         local('Avenir-Heavy'),
//         url(${AvenirHeavyUrl}) format('ttf')
//       `,
// };
