import React from 'react';
import { useTranslation } from 'react-i18next';
import MetaTitle from 'components/MetaTitle';
import ThankYou from './ThankYou';

const ThankYouPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <MetaTitle title={`${t('Portfolio.title')} : ${t('siteNameFull')}`} />
      <ThankYou />
    </>
  );
};

export default ThankYouPage;
