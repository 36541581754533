import { makeStyles } from "@mui/styles";

const HeaderStyles = makeStyles((theme) => ({
    topBar: {
        // border: `1px solid white`,
        width: `96%`,
        position: `absolute`,
        top: 50,
        left: `2%`,
        zIndex: 500,
        display: `flex`,
        justifyContent: `space-between`,
        alignItems: `center`,
        [theme.breakpoints.down('sm')]: {
            position: `static`,
            width: `100%`,
        },  
    },
    logoContainer: {
        flex: `0 0 50%`,
        [theme.breakpoints.down('sm')]: {
            display: `none`
        },  
    },
    btnContainer: {
        // position: `fixed`,
        top: 100,
        right: 20,
        zIndex: 500,
        flex: `0 0 50%`,
        display: 'flex',
        justifyContent: 'flex-end',
        '&.desktop': {
            position: `fixed`,
            top: 45,
            right: 20,
            zIndex: 500,
            [theme.breakpoints.down('sm')]: {
                display: `none`
            },
        },
        '&.mobile': {
            display: `none`,
            position: `static`,
            [theme.breakpoints.down('sm')]: {
                display: `flex`,
                paddingTop: 80,
                flex: `0 0 100%`,
                justifyContent: 'center',
            },
        }
    },
    topBarButton: {
        width: 200,
        height: 50,
        padding: 8,
        borderRadius: 20,
        backgroundColor: `#173F35`,
        color: `#fff`,
        fontFamily: `Montserrat-Regular`,
        fontWeight: 500,
        textTransform: 'uppercase',
        fontSize: 15,
        letterSpacing: 2,
        lineHeight: 1.5,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        cursor: `pointer`,
        
        [theme.breakpoints.down('sm')]: {
            width: `36%`,
            minWidth: 150,
            borderRadius: 50,
            fontSize: 18,
            lineHeight: 2,
            maxHeight: 60,
        },
        '&:hover': {
            color: `#173F35`,
            backgroundColor: `#869b83`,
        }
    }
}));

export default HeaderStyles;
