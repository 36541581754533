import React from 'react';
import Header from '../../_layout/Header'
import VideoStyles from "./_styles"

const Video = () => {
  const classes = VideoStyles();

  return (
    <section className={`parallaxSection ${classes.videoSection}`} id="video-section">
      <div className={classes.logoMobile}>
          <img
            src={`/img/logo/logo.svg`}
            alt='Heather Teaser'
          />
      </div>
      <div className={classes.videoWrapper}>
        <div className={classes.videoContent}>
          <video autoPlay muted loop playsInline>
            <source src={`/img/video/video.mp4`} type="video/mp4" />
          </video>
        </div>
        <div className={classes.videoMask} style={{ backgroundImage: `url('/img/video/videoMask.svg')`}} />
      </div>
      <Header />
    </section>
  );
};

export default Video;
